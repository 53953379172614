<template>
    <div>
        <b-alert :variant="loading?'primary':'danger'" :show="!items">
            <div class="alert-body py-3 px-2">
                <span><strong v-if="loading">Veriler yükleniyor. <b-spinner label="Loading..."/></strong><strong v-else>Veri bulunamadı.</strong></span>
            </div>
        </b-alert>
        <b-card v-if="items">

            <!-- table -->
            <vue-good-table
                :columns="columns"
                :rows="items"
                :search-options="{ enabled: false }"
                :pagination-options="{ enabled: false}"
                :sort-options="{ enabled: false }">
                <template
                    slot="table-row"
                    slot-scope="props"
                >

                    <div v-if="props.column.field === 'value'">
                        <b-form-input class="tableInput" v-model="items[getItemIndex(props.row.title)].value"
                                      :readonly="$store.state.app.editableSlotId !== props.row.title"/>
                    </div>
                    <div v-else-if="props.column.field === 'title'">
                        {{titlePretty[props.row.title]}}
                    </div>

                    <!-- Column: Action -->
                    <div v-else-if="props.column.field === 'action'">
                        <b-button-group>
                            <b-button variant="outline-primary" @click="updateItem(props.row.title)"
                                      v-if="$store.state.app.editableSlotId === props.row.title">
                                <feather-icon icon="SaveIcon"/>
                            </b-button>
                            <b-button variant="outline-primary" @click="setEditable(props.row.title)">
                                <feather-icon
                                    :icon="$store.state.app.editableSlotId !== props.row.title ? 'EditIcon':'RefreshCcwIcon'"/>
                            </b-button>
                        </b-button-group>
                    </div>

                    <!-- Column: Common -->
                    <div v-else class="d-flex align-items-center">
                        {{ props.formattedRow[props.column.field] }}
                    </div>
                </template>
            </vue-good-table>
        </b-card>
    </div>
</template>

<script>
import {
    BAlert,
    BSpinner,
    BCard,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BImg,
    BButtonGroup,
    BButton,
    BForm,
    BRow,
    BCol


} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import store from '@/store'
import formData from 'form-data'

export default {
    name: 'list',
    components: {
        BAlert,
        BSpinner,
        BCard,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        VueGoodTable,
        BImg,
        BButtonGroup,
        BButton,
        BForm,
        BRow,
        BCol

    },
    data() {
        return {
            loading: true,
            items: false,
            preChange: {},
            columns: [
                {
                    label: 'Ayar Adı',
                    field: 'title'
                },
                {
                    label: 'Ayar',
                    field: 'value'
                },
                {
                    label: 'İşlemler',
                    field: 'action',
                    sortable: false,
                    width: '160px',
                    align: 'center'
                },
            ],
            titlePretty: {
                'app_name': 'Uygulama Adı',
                'app_slogan': 'Uygulama Sloganı',
                'company_name': 'Şirket Adı',
                'tax_office': 'Vergi Dairesi',
                'tax_number': 'Vergi Numarası',
                'address': 'Adres',
                'phone': 'Telefon',
                'gsm': 'Gsm',
                'whatsapp_number': 'Whatsapp Numarası',
                'version': 'Versiyon',
                'maintain': 'Bakım Modu',
                'default_veterinarian_km': 'Veteriner Varsayılan Arama Mesafesi (km)',
                'default_pet_hotel_km': 'Pet Otel Varsayılan Arama Mesafesi (km)',
                'default_cafe_km': 'Cafe Restaurant Varsayılan Arama Mesafesi (km)',
                'default_hotel_km': 'Otel Varsayılan Arama Mesafesi (km)',
                'default_pet_shop_km': 'Pet Shop Varsayılan Arama Mesafesi (km)',
                'default_training_center_km': 'Eğitim Merkezi Varsayılan Arama Mesafesi (km)',
                'owner_first_membership_period': 'Pet Sahibi ilk Üyelik Süresi',
                'company_first_membership_period': 'Firma ilk Üyelik Süresi',
                'bank_commission': 'Banka Komisyonu (%)',
                'petsomy_commission': 'Petsomy Komisyonu (%)',
            }
        }
    },
    created() {
        let t = this
        t.$http.get('/misc/general/getSettings')
            .then(res => {
                if (res.data.status) {
                    let keys = Object.keys(res.data.data)
                    t.items = []
                    for (let m = 0; m < keys.length; m++) {
                        let key = keys[m]
                        t.items.push({title: key, value: res.data.data[key]})
                    }
                } else if (res.data.message) {
                    t.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Hata`,
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                            text: res.data.message,
                        },
                    })
                }
            }).catch(error => {
            console.log(error)
        }).then(() => {
            t.loading = false
        })

        store.commit('app/SET_EDITABLE', 0)
    },
    methods: {
        setEditable(itemId) {
            let fIndex = this.getItemIndex(itemId)
            let t = this
            if (store.state.app.editableSlotId === 0) {
                this.preChange = structuredClone(this.items[fIndex])
                store.commit('app/SET_EDITABLE', itemId)
            } else if (store.state.app.editableSlotId === itemId) {
                this.$swal({
                    title: 'Emin misiniz?',
                    text: "Yaptığınız değişiklikler kaybolacaktır.",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Evet',
                    cancelButtonText: 'İptal',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                    showLoaderOnConfirm: true
                }).then(result => {
                    if (result.isConfirmed) {
                        this.resetItems(fIndex)
                        store.commit('app/SET_EDITABLE', 0)
                    }
                })
            } else {
                this.$swal({
                    title: 'Emin misiniz?',
                    text: "Mevcut düzenlemeyi tamamlamadan devam ederseniz, yaptığınız değişiklikler kaybolacaktır.",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Evet',
                    cancelButtonText: 'İptal',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                    showLoaderOnConfirm: true
                }).then(result => {
                    if (result.isConfirmed) {
                        this.resetItems(t.getItemIndex(store.state.app.editableSlotId))
                        t.preChange = structuredClone(t.items[fIndex])
                        store.commit('app/SET_EDITABLE', itemId)
                    }
                })
            }
        },
        updateItem(id) {

            let t = this
            let data = new formData()
            let fIndex = this.getItemIndex(id)
            let item = this.items[fIndex]

            data.append('title', item.title)
            data.append('value', item.value)

            t.$http.post(
                'admin/settings/update',
                data
            ).then((result) => {
                if (result.data.status) {
                    if (result.data.message) {
                        t.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Başarılı!`,
                                icon: 'CheckIcon',
                                variant: 'success',
                                text: result.data.message,
                            },
                        })
                    }
                } else {
                    t.$swal({
                        title: 'Hata!',
                        text: result.data.message ? result.data.message : 'Ayar Güncellenemedi. Lütfen daha sonra tekrar deneyin.',
                        icon: 'error',
                        confirmButtonText: 'Tamam'
                    })
                    t.resetItems(fIndex)
                }
            }).catch((error) => {
                console.log(error)

                t.$swal({
                    title: 'Hata!',
                    text: 'İçerik Güncellenemedi. Lütfen daha sonra tekrar deneyin.',
                    icon: 'error',
                    confirmButtonText: 'Tamam'
                })
                t.resetItems(fIndex)
            }).then((e) => {
                store.commit('app/SET_EDITABLE', 0)
            })

        },
        getItemIndex(id) {
            return this.items.findIndex(e => {
                return e.title === id
            })
        },
        resetItems(curr) {
            let t = this;
            let keys = Object.keys(t.preChange)
            for (let i = 0; i < keys.length; i++) {
                t.items[curr][keys[i]] = t.preChange[keys[i]]
            }
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

.tableInput[readonly] {
    border: none !important;
}
</style>
